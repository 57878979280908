<template>
  <div class="rewiews wrap px-3">
    <div class="h1 mt-6">Reviews</div>
    <v-card min-height="70vh" tile flat v-if="!data.total" class="d-flex flex-column align-center justify-center">
      <v-img max-height="140" contain src="@/assets/img/empty-reviews.svg"></v-img>
      <div class="h4 mt-7">There will be your reviews</div>
    </v-card>
    <div v-else>
      <div class="d-flex align-end mb-8">
        <v-rating
          empty-icon="mdi-star"
          length="5"
          size="22"
          v-model="totalRetimg"
          background-color="gray"
          color="warning"
          half-increments
          readonly
        ></v-rating>
        <div class="h5 ms-2">{{ totalRetimg }} overall rating</div>
      </div>
      <div v-for="item in data.results" :key="item.id" class="rewiews-card mt-1">
        <div class="d-flex align-start">
          <div class="d-flex align-center rewiews-card_item mt-2">
            <v-avatar size="42" color="primary">
              <v-img v-if="item.tutor.picture" :src="item.tutor.picture"></v-img>
              <v-icon v-else color="white">mdi-account mdi-36px</v-icon>
            </v-avatar>
            <div class="ms-2">
              <div class="h7">{{ item.tutor.full_name }}</div>
              <div class="h10 text--text"></div>
            </div>
          </div>
          <div class="rewiews-card_item">
            <v-rating
              empty-icon="mdi-star"
              length="5"
              size="14"
              v-model="item.rating"
              background-color="gray"
              color="warning"
              half-increments
              readonly
            ></v-rating>
            <div class="h7 mb-1">{{ item.course.title }}</div>
            <div class="h8 text--text mb-2">{{ item.text }}</div>
          </div>
          <div class="h10 pt-1 text--text text-end rewiews-card_item" v-if="item.date">
            {{ new Date(item.date).toLocaleString().substr(0, 10) }}
          </div>
        </div>
        <v-divider></v-divider>
      </div>
      <div class="mt-6" v-if="data.pages > 1">
        <v-divider></v-divider>
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-pagination class="mt-1" color="accent" v-model="page" :length="data.pages" total-visible="5"></v-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      page: +this.$route.query.page || 1,
      error: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await this.$store.dispatch('getRewiewsList', this.page);
    },
  },
  watch: {
    page() {
      this.getData();
    },
  },
  computed: {
    data() {
      return this.$store.getters.rewiewsList;
    },
    totalRetimg() {
      return this.$store.getters.profile.rating;
    },
  },
  destroyed() {
    this.$store.dispatch('setRewiewsList', {});
  },
};
</script>

<style lang="scss">
#app .rewiews {
  .v-rating {
    button {
      padding: 2px;
    }
  }
  .v-pagination {
    button {
      box-shadow: initial;
    }
    .v-pagination__item {
      background: var(--v-white-base);
      color: var(--v-gray-base);
    }
    .v-pagination__navigation {
      background: var(--v-accent-base);
    }
    .v-pagination__item--active {
      color: var(--v-text-base);
    }
  }
  &-card {
    &_item {
      padding-right: 20px;
      max-width: 280px;
      width: 100%;
    }
    &_item:nth-child(2) {
      max-width: 465px;
    }
    &_item:last-child {
      max-width: 125px;
    }
  }
}
</style>
